import * as React from "react"

const Teaser = ({ blok }) => (
  <div>
    <h3
      className="headline text-3xl font-black text-center pt-28 pb-10"
      data-sal="slide-down"
      data-sal-delay="300"
      data-sal-easing="ease"
    >
      { blok.headline }
    </h3>
  </div>
)

export default Teaser