import React from 'react'
import SbEditable from 'storyblok-react'
import PodcastTeaser from './PodcastTeaser'

const FeaturedPodcast = ({ blok }) => {
  return (
    <SbEditable content={ blok } key={ blok._uid }>
      <div className="mb-6 container mx-auto text-left" key={ blok._uid }>
        <ul className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          {
            blok.podcasts.map(podcasts => (
              <li key={ podcasts._uid } className="w-full list-none">
                <PodcastTeaser blok={ podcasts } />
              </li>
              )
            )
          }
        </ul>
      </div>
    </SbEditable>
  )
}

export default FeaturedPodcast