import React from 'react'
import SbEditable from 'storyblok-react'
import ArticleTeaser from './ArticleTeaser'

const FeaturedArticles = ({ blok }) => {
  return (
    <SbEditable content={ blok } key={ blok._uid }>
      <div className="mb-6 container mx-auto text-left" key={ blok._uid }>
        <ul className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          {
            blok.articles.map(article => (
              <li
                key={ article._uid }
                className="w-full list-none"
                data-sal="slide-up"
                data-sal-delay="300"
                data-sal-easing="ease">
                <ArticleTeaser blok={ article } />
              </li>
              )
            )
          }
        </ul>
      </div>
    </SbEditable>
  )
}

export default FeaturedArticles