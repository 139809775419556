import React from 'react'
import SbEditable from 'storyblok-react'
import Teaser from './Teaser'
import Grid from './Grid'
import Feature from './Feature'
import PostsList from './PostsList'
import TalkGrid from './TalkGrid'
import TalkTeaser from './TalkTeaser'
import TalkFeature from './TalkFeature'
import FeaturedArticles from './FeaturedArticles'
import ArticleTeaser from './ArticleTeaser'
import FeaturedPodcast from './FeaturedPodcast'
import PodcastTeaser from './PodcastTeaser'

const Components = {
  'teaser': Teaser,
  'grid': Grid,
  'feature': Feature,
  'posts-list': PostsList,
  'talk_grid': TalkGrid,
  'talk_teaser': TalkTeaser,
  'talk-feature': TalkFeature,
  'featured-articles': FeaturedArticles,
  'article-teaser': ArticleTeaser,
  'featured-podcast': FeaturedPodcast,
  'podcast-teaser': PodcastTeaser
}

const DynamicComponent = ({ blok }) => {
  if (typeof Components[blok.component] !== 'undefined') {
    const Component = Components[blok.component]
    return (<SbEditable content={blok}><Component blok={blok} /></SbEditable>)
  }
  return (<p>The component <strong>{blok.component}</strong> has not been created yet.</p>)
}

export default DynamicComponent