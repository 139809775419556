import React from 'react'
import SbEditable from 'storyblok-react'

const PodcastTeaser = ({ blok }) => {
  return (
    <SbEditable content={ blok } key={ blok._uid }>
      <div
        className="grid justify-items-center"
        data-sal="slide-up"
        data-sal-delay="300"
        data-sal-easing="ease"
      >
        <a href={ blok.podcasts.url } className="grid grid-cols-1 justify-items-center icon-url py-16 cursor-pointer">
          <img
            className="rounded-sm transition hover:opacity-50"
            src={ blok.episode_image.filename }
            alt={ blok.episode_image.alt }
            style={{ boxShadow: `0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)` }} />
          <h2 className="pb-6 text-lg font-bold">{ blok.episode_title }</h2>
        </a>
      </div>
    </SbEditable>
  )
}

export default PodcastTeaser