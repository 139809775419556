import * as React from "react"

const TalkTeaser = ({ blok }) => (
  <div className="py-14">
    <h1
      className="header-gradetion headline text-5xl font-black text-center pt-3 pb-10"
      data-sal="slide-up"
      data-sal-delay="300"
      data-sal-easing="ease"
    >
      { blok.talk_headline }
    </h1>
  </div>
)

export default TalkTeaser