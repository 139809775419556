import React from 'react'
import DynamicComponent from '../components/dynamicComponent'
import SbEditable from 'storyblok-react'

const TalkGrid = ({ blok }) => {
  return (
    <SbEditable content={ blok } key={ blok._uid }>
      <ul className="grid grid-cols-1 lg:grid-cols-2 list-none gap-x-6">
        {blok.columns.map(blok => (
          <li key={ blok._uid } className="text-center">
            <DynamicComponent blok={ blok } />
          </li>
        ))}
      </ul>
    </SbEditable>
  )
}

export default TalkGrid