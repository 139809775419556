import React from 'react'
import SbEditable from 'storyblok-react'

const Feature = ({ blok }) => {
  return (
    <SbEditable content={ blok } key={ blok._uid }>
      <div
        className="grid justify-items-center"
        data-sal="slide-down"
        data-sal-delay="350"
        data-sal-easing="ease"
      >
        <a className="icon-url" href={ blok.iconUrl.url }>
          <img className="shadow-none" src={ blok.socialIcons.filename } alt={ blok.socialIcons.alt } />
        </a>
      </div>
    </SbEditable>
  )
}

export default Feature