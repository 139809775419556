import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Link from 'gatsby-link'

export default function ArticleTeaser({ blok }){
  const articles = useStaticQuery(graphql`
    {
      # 👇 include in a path to fetch further edges data below
      posts: allStoryblokEntry(filter: {field_component: {eq: "Post"}}) {
        edges {
          node {
            name
            full_slug
            content
          }
        }
      }
    }
  `)
  // had to include "posts" from query name above
  let article = articles.posts.edges.filter(({ node }) => node.full_slug === blok.article.cached_url)
  let content = article.length ? JSON.parse(article[0].node.content) : {};

  return (
      <Link to={`/${article[0].node.full_slug}`} className="icon-url py-16 block transition hover:opacity-50">
        <img src={ content.image } alt={ content.title } className="w-full h-52 object-cover rounded-sm"/>
        <h2 className="pb-6 text-lg font-bold">{ content.title }</h2>
        <p className="pb-6 text-gray-500 leading-loose">{ content.intro }</p>
      </Link>
  )
}