import React from 'react'
import DynamicComponent from '../components/dynamicComponent'
import SbEditable from 'storyblok-react'

const Grid = ({ blok }) => {
  return (
    <SbEditable content={ blok } key={ blok._uid }>
      <ul className="grid grid-cols-2 md:grid-cols-4 list-none">
        {blok.columns.map(blok => (
          <li key={ blok._uid } className="text-center">
            <DynamicComponent blok={ blok } />
          </li>
        ))}
      </ul>
      <h3
        className="headline text-3xl font-black text-center pt-28 pb-10"
        data-sal="slide-down"
        data-sal-delay="300"
        data-sal-easing="ease"
      >
        { blok.bioHeadline }
      </h3>
      <div
        data-sal="slide-up"
        data-sal-delay="350"
        data-sal-easing="ease"
      >
        <p className="bio-option pt-5 text-xl">Tall</p>
        <p className="py-5 text-gray-500">{ blok.bioShort }</p>
      </div>
      <div
        data-sal="slide-up"
        data-sal-delay="350"
        data-sal-easing="ease"
      >
        <p className="bio-option pt-5 text-xl">Grande</p>
        <p className="py-5 text-gray-500">{ blok.bioMiddle }</p>
      </div>
      <div
        data-sal="slide-up"
        data-sal-delay="350"
        data-sal-easing="ease"
      >
        <p className="bio-option pt-5 text-xl">Venti</p>
        <p className="py-5 text-gray-500">{ blok.bio }</p>
      </div>
    </SbEditable>
  )
}

export default Grid