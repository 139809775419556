import React from 'react'
import SbEditable from 'storyblok-react'

const TalkFeature = ({ blok }) => {
  return (
    <SbEditable content={ blok } key={ blok._uid }>
      <div
        className="grid justify-items-center"
        data-sal="slide-up"
        data-sal-delay="300"
        data-sal-easing="ease"
      >
        <a href={ blok.talk_url.url }>
          <h2>{ blok.talk_title }</h2>
        </a>
        <a className="icon-url" href={ blok.talk_url.url }>
          <img className="rounded-sm shadow-md" src={ blok.talk_hero.filename } alt={ blok.talk_hero.alt } />
        </a>
        <h5 className="text-left w-full">{ blok.talk_date.slice(0, 10) }</h5>
        <p className="text-left text-gray-500">{ blok.talk_description }</p>
      </div>
    </SbEditable>
  )
}

export default TalkFeature